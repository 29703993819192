import { createFeatureSelector, createSelector } from '@ngrx/store';
import { fromProjects } from '.';

const { selectIds, selectEntities, selectAll, selectTotal } = fromProjects.adapter.getSelectors();

export const selectProjectsState = createFeatureSelector<fromProjects.State>(fromProjects.projectsFeatureKey);

export const selectProjectsLoading = createSelector(selectProjectsState, (state) => state.loading);

export const selectProjectMixInProgress = createSelector(selectProjectsState, (state) => state.mixInProgress);

export const selectCurrentProjectMix = createSelector(selectProjectsState, (state) => state.currentMix);

export const selectProjects = createSelector(selectProjectsState, selectAll);

export const selectProjectsLength = createSelector(selectProjects, (projects) => projects.length);

export const selectProjectEntities = createSelector(selectProjectsState, selectEntities);

export const selectCurrentProject = createSelector(selectProjectsState, (state) => {
  return state.selectedProject;
});

export const selectCurrentProjectId = createSelector(selectCurrentProject, (project) => project?.id);

export const selectErrorOccurred = createSelector(selectProjectsState, ({ errorOccurred }) => errorOccurred);
