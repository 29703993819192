<div class="sidebar">
  <div>
    <img [ngClass]="{' mb-5': !(hasPremium$ | async)}" class="logo mt-2" src="assets/images/logo.svg"/>
    <div *ngIf="hasPremium$ | async" class="premium-label">PREMIUM ACCESS</div>
    <div *ngFor="let group of menu$ | async" class="link-group d-flex flex-column mt-4">
      <p class="mxc-label-m bolder">{{ group.groupTitle }}</p>
      <div *ngFor="let item of group.children" [routerLink]="item.redirect" class="link"
           routerLinkActive="link__active">
        <img [src]="'assets/icons/' + item.svgIcon + '.svg'" class="icon"/>
        <p class="mxc-body-m link__content">
          {{ item.title }}
        </p>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center gap-2">
    <ng-container *ngIf="user$ | async as user; else guestTemplate">
      <p class="mxc-label-m">Logged in as</p>
      <p class="mxc-label-m bold">{{ user.name }}</p>
    </ng-container>
    <ng-template #guestTemplate>
      <p class="mxc-label-m bold">Guest</p>
      <button class="mxc-button mxc-button--primary w-100" routerLink="/landing/login" type="button">Login</button>
      <button class="mxc-button mxc-button--secondary w-100" routerLink="/landing/register" type="button">Sign up
      </button>
    </ng-template>
  </div>
</div>
