export const environment = {
  createAdminCredentials: {
    name: 'admin',
    password: 'admin',
  },
  payments: {
    publicKey: 'pk_test_51PLT7H02VKIB1n1FjWct2O04xpgVt8U3eGDRhhHgk8Y8PeUfz7VWhACdfWSapqPV7Da4Nvcq1YeITJynmZoVnVRd00hkC6HTJq',
    secretKey: 'sk_test_51PLT7H02VKIB1n1Fd2jMGhMFo398MDSnHZ7NvpR7thBvMvBDpH630mhQMDH3kgXqxBoFy5ZDBVFZN6y86m88QbBx00jER2z0gW',
    product: {
      id: 'prod_QCtw4ZBUaqvqbL',
      priceId: 'price_1PMU8R02VKIB1n1FNR2S8Zkg',
      currency: 'USD',
      amount: 500,
    },
  },
  stripePublicKey: 'pk_test_51PLT7H02VKIB1n1FjWct2O04xpgVt8U3eGDRhhHgk8Y8PeUfz7VWhACdfWSapqPV7Da4Nvcq1YeITJynmZoVnVRd00hkC6HTJq',
  stripeSecretKey: 'sk_test_51PLT7H02VKIB1n1Fd2jMGhMFo398MDSnHZ7NvpR7thBvMvBDpH630mhQMDH3kgXqxBoFy5ZDBVFZN6y86m88QbBx00jER2z0gW',
  apiUrl: 'https://api.mix-console.dev.aws.tss.black',
  paymentUrl: 'https://api.stripe.com/v1',
};
