import { createReducer, on } from '@ngrx/store';
import { User, UserActionsErrorCode } from '../../interfaces/user/user';
import { UserActions } from './';

export const userFeatureKey = `user`;

export interface State {
  user: User;
  isLoading?: boolean;
  isUserCreated?: boolean;
  errorCode: UserActionsErrorCode;
}

export const initialState: State = {
  user: null,
  isLoading: false,
  isUserCreated: false,
  errorCode: null,
};

export const reducer = createReducer(
  initialState,
  on(
    UserActions.loadUser,
    UserActions.createUser,
    UserActions.login,
    UserActions.createUserSuccess,
    (state): State => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    UserActions.loadUserSuccess,
    (state, { user }): State => ({
      ...state,
      user: { ...user },
      isLoading: false,
    }),
  ),
  on(UserActions.userActionFailure, (state, { errorCode }): State => ({ ...state, isLoading: false, errorCode })),
  on(UserActions.logout, (state): State => ({ ...state, user: null })),
  on(UserActions.createUserSuccess, (state): State => ({ ...state, isUserCreated: true, isLoading: false })),
  on(UserActions.clearAfterUserCreate, (state): State => ({ ...state, isUserCreated: false })),
);
