export interface User {
  id: string;
  name: string;
  email: string;
  premium_active?: boolean;
  roles?: string[];
}

export interface CreateUserPayload {
  name: string;
  email: string;
  password: string;
  isAdmin?: boolean;
}

export enum UserActionsErrorCode {
  NAME_TAKEN = 'NameTaken',
  EMAIL_TAKEN = 'EmailTaken',
  USER_NOT_EXIST = 'UserNotExist',
  INVALID_EMAIL = 'InvalidEmail',
  INVALID_PASSWORD = 'InvalidPassword',
  SERVER_ERROR = 'SERVER_ERROR',
}
