import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CreateUserPayload, User } from '../../interfaces/user/user';
import { ApiService } from '../base/api.service';
import { CreateUserResponse, FetchUserResponse } from './user-http.models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiService) {}

  fetchUser(): Observable<User> {
    return this.apiService.get<FetchUserResponse>('/user').pipe(map(({ user_id: id, ...userProps }) => ({ id, ...userProps })));
  }

  createUser(payload: CreateUserPayload): Observable<User> {
    return this.apiService.post<CreateUserResponse>('/user', payload).pipe(
      map(({ user_id: id, name }) => ({
        id,
        email: payload.email,
        name,
      })),
    );
  }
}
