import { ActionReducerMap } from '@ngrx/store';
import { fromProjects } from './projects';
import { fromTracks } from './tracks';
import { fromUser } from './user';

export interface State {
  [fromProjects.projectsFeatureKey]: fromProjects.State;
  [fromTracks.tracksFeatureKey]: fromTracks.State;
  [fromUser.userFeatureKey]: fromUser.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromProjects.projectsFeatureKey]: fromProjects.reducer,
  [fromTracks.tracksFeatureKey]: fromTracks.reducer,
  [fromUser.userFeatureKey]: fromUser.reducer,
} as ActionReducerMap<State>;
