import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

type Params =
  | HttpParams
  | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly apiUrl = environment.apiUrl;

  // private readonly apiUrl = 'http://127.0.0.1:8000';

  constructor(private http: HttpClient) {}

  get<T>(endpoint: string, options?: any): Observable<T> {
    return this.http.get<T>(`${this.apiUrl}${endpoint}`, options).pipe(catchError(this.handleError)) as Observable<T>;
  }

  post<T>(endpoint: string, data?: unknown, options?: any): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, options).pipe(catchError(this.handleError)) as Observable<T>;
  }

  put<T>(endpoint: string, data?: unknown, params?: Params): Observable<T> {
    return this.http.put<T>(`${this.apiUrl}${endpoint}`, data, { params }).pipe(catchError(this.handleError));
  }

  delete(endpoint: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}${endpoint}`).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError(error);
  }
}
