import { Injectable } from '@angular/core';
import { catchError, delayWhen, forkJoin, map, Observable, of, switchMap, timer } from 'rxjs';
import { Track, TrackSimpleData } from '../../interfaces/tracks/track';
import { ApiService } from '../base/api.service';
import {
  FetchTrackSourcePayload,
  FetchTracksPayload,
  RemoveTrackPayload,
  UploadTracksPayload,
} from '../../interfaces/tracks/tracks-payload.models';
import { removeFileExtension } from '../../utils/remove-file-extension.util';

@Injectable({
  providedIn: 'root',
})
export class TracksService {
  constructor(private readonly apiService: ApiService) {}

  fetchTracks({ projectId }: FetchTracksPayload): Observable<Track[]> {
    return this.apiService.get<{ tracks: TrackSimpleData[] }>(`/projects/${projectId}/tracks`).pipe(
      switchMap(({ tracks }) => {
        if (tracks.length === 0) {
          return of([]);
        }
        return forkJoin([
          ...tracks.map(({ id, title }, index) =>
            this.fetchTrackSource({
              projectId,
              trackId: id,
              title,
            }).pipe(
              map((file) => ({ title: removeFileExtension(title), id, file }) as Track),
              catchError(() => of(null)),
              delayWhen(() => timer(index * 500)),
            ),
          ),
        ]);
      }),
    );
  }

  fetchTrackSource({ projectId, trackId, title }: FetchTrackSourcePayload): Observable<File> {
    return this.apiService
      .get<Blob>(`/projects/${projectId}/tracks/${trackId}`, {
        responseType: 'blob',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      })
      .pipe(
        map((blob) => {
          return new File([blob], `${title}`, { type: blob?.type });
        }),
      );
  }

  uploadTracks({ projectId, files }: UploadTracksPayload): Observable<void> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('uploaded_files', files[i]);
    }
    return this.apiService.post<void>(`/projects/${projectId}/tracks`, formData);
  }

  removeTrackForId({ projectId, trackId }: RemoveTrackPayload): Observable<void> {
    return this.apiService.delete(`/projects/${projectId}/tracks/${trackId}`);
  }

  uploadReferenceTracks(files: File[], directoryName: string): Observable<void> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('uploaded_files', files[i]);
    }
    if (directoryName) {
      formData.append('directory_name', directoryName);
    }
    return this.apiService.post<void>(`/uploads/local/reference-tracks`, formData);
  }

  clearReferenceTracks(): Observable<void> {
    return this.apiService.delete(`/uploads/local/reference-tracks`);
  }
}
