import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Project } from '../../interfaces/projects/project.models';
import { ProjectsActions } from '.';
import { Nullable } from '../../core/types/nullable.type';
import { HttpErrorResponse } from '@angular/common/http';

export const projectsFeatureKey = `projects`;

export interface State extends EntityState<Project> {
  loading: boolean;
  mixInProgress: boolean;
  shouldMix: boolean;
  currentMix: Nullable<File>;
  selectedProject: Nullable<Project>;
  errorOccurred: HttpErrorResponse;
}

export const adapter = createEntityAdapter<Project>({
  selectId: (project) => project?.id,
});

export const initialState: State = adapter.getInitialState({
  selectedProject: null,
  loading: false,
  mixInProgress: false,
  currentMix: null,
  shouldMix: false,
}) as State;

export const reducer = createReducer(
  initialState,
  on(ProjectsActions.projectFailure, (state): State => ({ ...state, loading: false })),
  on(
    ProjectsActions.loadProjects,
    ProjectsActions.fetchProjectDetails,
    ProjectsActions.updateProject,
    ProjectsActions.createProject,
    ProjectsActions.deleteProject,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(ProjectsActions.loadProjects, (state): State => adapter.removeAll(state)),
  on(ProjectsActions.loadProjectsSuccess, (state, { projects }): State => {
    return adapter.upsertMany(projects, {
      ...state,
      loading: false,
    });
  }),
  on(
    ProjectsActions.mixCurrentProject,
    (state): State => ({
      ...state,
      mixInProgress: true,
      currentMix: null,
      errorOccurred: null,
    }),
  ),
  on(ProjectsActions.projectMixedSuccess, (state, { currentMix }): State => {
    return {
      ...state,
      mixInProgress: false,
      currentMix,
    };
  }),
  on(ProjectsActions.projectSuccess, (state): State => ({ ...state, loading: false })),
  on(ProjectsActions.projectMixFailure, (state, { error }): State => ({ ...state, errorOccurred: error })),
  on(ProjectsActions.resetProjectDetails, (state): State => ({ ...state, selectedProject: null })),
  on(ProjectsActions.fetchProjectDetailsSuccess, (state, { selectedProject }): State => {
    return {
      ...state,
      selectedProject,
      loading: false,
    };
  }),
);
