import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, of, switchMap, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserActions } from './';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserActionsErrorCode } from '../../interfaces/user/user';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect((): any => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser),
      debounceTime(100),
      switchMap(() =>
        this.userService.fetchUser().pipe(
          switchMap((user) => [UserActions.loadUserSuccess({ user })]),
          catchError((error) => of(UserActions.userActionFailure({}))),
          takeUntil(this.actions$.pipe(ofType(UserActions.loadUser))),
        ),
      ),
    );
  });

  createUser$ = createEffect((): any => {
    return this.actions$.pipe(
      ofType(UserActions.createUser),
      switchMap(({ payload }) =>
        this.userService.createUser(payload).pipe(
          switchMap(() => [UserActions.createUserSuccess(), UserActions.clearAfterUserCreate()]),
          catchError((error: HttpErrorResponse) => {
            return of(UserActions.userActionFailure({ errorCode: error?.error?.detail?.errorCode || UserActionsErrorCode.SERVER_ERROR }));
          }),
          takeUntil(this.actions$.pipe(ofType(UserActions.loadUser))),
        ),
      ),
    );
  });

  login$ = createEffect((): any => {
    return this.actions$.pipe(
      ofType(UserActions.login),
      switchMap(({ payload }) =>
        this.authService.login(payload).pipe(
          switchMap((user) => [UserActions.loadUserSuccess({ user })]),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.userActionFailure({ errorCode: error?.error?.detail?.errorCode || UserActionsErrorCode.SERVER_ERROR })),
          ),
          takeUntil(this.actions$.pipe(ofType(UserActions.loadUser))),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly userService: UserService,
    private readonly authService: AuthService,
  ) {}
}
