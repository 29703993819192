import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { User } from '../../interfaces/user/user';
import { ApiService } from '../base/api.service';
import { LoginPayload } from '../../interfaces/auth/auth';
import { LoginResponse } from './auth-http.models';
import { UserService } from '../user/user.service';
import { Nullable } from '../../core/types/nullable.type';
import { Store } from '@ngrx/store';
import { logout } from '@store/user/user.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenSubject = new BehaviorSubject<Nullable<string>>(null);

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private store: Store,
  ) {}

  get token$(): Observable<Nullable<string>> {
    return this.tokenSubject.asObservable();
  }

  get token(): Nullable<string> {
    return this.tokenSubject.value;
  }

  setupTokenIfAvailable(): void {
    const token = localStorage.getItem('token');
    if (token) {
      this.tokenSubject.next(token);
    }
  }

  login(payload: LoginPayload): Observable<User> {
    return this.apiService.post<LoginResponse>('/user/auth', payload).pipe(
      tap(({ access_token }) => {
        localStorage.setItem('token', access_token);
        this.tokenSubject.next(access_token);
      }),
      switchMap(() => this.userService.fetchUser()),
    );
  }

  logout(): void {
    localStorage.removeItem('token');
    this.tokenSubject.next(null);
    this.store.dispatch(logout());
  }

  requestForResetPassword(email: string): Observable<void> {
    return this.apiService.post<void>('/user/reset-password', null, { email });
  }

  // changePassword(payload: ChangePasswordPayload): Observable<> {
  //   return this.apiService.put<void>('/user/reset-password', { ...payload });
  // }
}
