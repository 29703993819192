import { Track } from '../tracks/track';
import { Nullable } from '../../core/types/nullable.type';

export interface ProjectData {
  id: string;
  title: string;
  createdAt: string;
  tracksCount: number;
  tracksLength: number;
}

export class Project {
  constructor(
    private data: ProjectData,
    private tracksData?: Track[],
    private mix?: File,
  ) {}

  get id(): string {
    return this.data.id;
  }

  get title(): string {
    return this.data.title;
  }

  get tracksCount(): number {
    return this.data.tracksCount;
  }

  get date(): string {
    return this.data.createdAt;
  }

  get mixedAudio(): Nullable<File> {
    return this.mix || null;
  }

  get tracks(): Nullable<Track[]> {
    return this.tracksData;
  }
}
