import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TracksActions } from '.';
import { catchError, debounceTime, of, switchMap, takeUntil, throwError } from 'rxjs';
import { TracksService } from '../../services/tracks/tracks.service';
import { ProjectsActions } from '@store/projects';

@Injectable()
export class TracksEffects {
  loadTracks$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(TracksActions.loadTracks),
      debounceTime(100),
      switchMap(({ params }) =>
        this.tracksService.fetchTracks(params).pipe(
          switchMap((tracks) => [TracksActions.loadTracksSuccess({ tracks })]),
          catchError((error) => of(TracksActions.loadTracksFailure({ error }))),
          takeUntil(this.actions$.pipe(ofType(TracksActions.loadTracks))),
        ),
      ),
    ),
  );

  removeTrackForId$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(TracksActions.removeTrackForId),
      debounceTime(100),
      switchMap((payload) =>
        this.tracksService.removeTrackForId(payload).pipe(
          switchMap(() => [ProjectsActions.fetchProjectDetails({ projectId: payload.projectId })]),
          catchError((error) => {
            console.error('Error occured during remove track try');
            return throwError(error);
          }),
          takeUntil(this.actions$.pipe(ofType(TracksActions.removeTrackForId))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly tracksService: TracksService,
  ) {}
}
