import { createAction, props } from '@ngrx/store';
import { CreateUserPayload, User, UserActionsErrorCode } from '../../interfaces/user/user';
import { LoginPayload } from '../../interfaces/auth/auth';

export const loadUser = createAction(`[User] Load User`);

export const loadUserSuccess = createAction(`[User] Load User Success`, props<{ user: User }>());

export const userActionFailure = createAction(
  `[User] User Action Failure`,
  props<{
    errorCode?: UserActionsErrorCode;
  }>(),
);

export const createUser = createAction(`[User] Create User`, props<{ payload: CreateUserPayload }>());
export const clearAfterUserCreate = createAction(`[User] Clear After User Create`);

export const createUserSuccess = createAction(`[User] Create User Success`);

export const login = createAction(`[User] Login`, props<{ payload: LoginPayload }>());

export const logout = createAction(`[User] Logout`);
