import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import { selectUser } from '@store/user/user.selectors';
import { map } from 'rxjs';

export const loggedOutGuard: CanActivateFn = (next, state) => {
  const store$ = inject(Store);
  const router = inject(Router);
  return store$.select(selectUser).pipe(
    map((user) => {
      if (user) {
        void router.navigate(['/projects']);
        return false;
      }
      return true;
    }),
  );
};
