import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, map, mapTo, Observable, of, switchMap } from 'rxjs';
import { ApiService } from '../base/api.service';
import { Project, ProjectData } from '../../interfaces/projects/project.models';
import { CreateProjectPayload, UpdateProjectPayload } from '../../interfaces/projects/project-payload.models';
import { CreateProjectResponse } from '../../interfaces/projects/project-http.models';
import { TracksService } from '../tracks/tracks.service';
import { Track } from '../../interfaces/tracks/track';
import { Nullable } from '../../core/types/nullable.type';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private areChangesPendingSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apiService: ApiService,
    private readonly tracksService: TracksService,
  ) {}

  get areChangesPending(): boolean {
    return this.areChangesPendingSubject.value;
  }

  setAreChangesPending(value: boolean): void {
    this.areChangesPendingSubject.next(value);
  }

  fetchProjectsData(): Observable<ProjectData[]> {
    return this.apiService.get<{ projects: ProjectData[] }>(`/projects/`).pipe(map(({ projects }) => projects));
  }

  downloadFile(file: File, name?: string): void {
    const fileURL = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = fileURL;
    link.download = name || file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  fetchProjectsWithMixins(): Observable<Project[]> {
    return this.apiService
      .get<{
        projects: ProjectData[];
      }>(`/projects/`)
      .pipe(
        map(({ projects }) => projects),
        switchMap((projects) => {
          if (projects.length === 0) {
            return of([]);
          }
          return forkJoin([
            ...projects.map((project) => this.fetchProjectMix(project.id).pipe(map((mix) => new Project(project, [], mix)))),
          ]);
        }),
      );
  }

  fetchProjectsLength(): Observable<number> {
    return this.apiService
      .get<{
        projects: ProjectData[];
      }>(`/projects/`)
      .pipe(map((response) => response.projects.length));
  }

  fetchProject(projectId: string): Observable<ProjectData> {
    return this.apiService.get<{ project: ProjectData }>(`/projects/${projectId}`).pipe(map(({ project }) => project));
  }

  fetchProjectWithDetails(projectId: string): Observable<Project> {
    return forkJoin([this.fetchProject(projectId), this.tracksService.fetchTracks({ projectId }), this.fetchProjectMix(projectId)]).pipe(
      map(([project, tracks, mix]) => {
        return new Project(project as ProjectData, tracks.filter(Boolean) as Track[], mix);
      }),
    );
  }

  deleteProject(projectId: string): Observable<void> {
    return this.apiService.delete(`/projects/${projectId}`);
  }

  updateProject({ id, title, files }: UpdateProjectPayload): Observable<void> {
    const sources$ = [
      title ? this.apiService.put<void>(`/projects/`, { id, title }) : null,
      this.tracksService.uploadTracks({ projectId: id, files }),
    ].filter(Boolean);

    return forkJoin(sources$).pipe(mapTo(null));
  }

  createProject({ title, files }: CreateProjectPayload): Observable<{ projectId: string }> {
    if (files.length === 0) {
      return this.apiService.post<CreateProjectResponse>(`/projects/`, { title }).pipe(map(({ id }) => ({ projectId: id })));
    }
    return this.apiService.post<CreateProjectResponse>(`/projects/`, { title }).pipe(
      switchMap(({ id }) => {
        return this.tracksService
          .uploadTracks({
            projectId: id,
            files,
          })
          .pipe(map(() => ({ projectId: id })));
      }),
    );
  }

  createProjectMix(projectId: string, extension = '.wav'): Observable<Nullable<File>> {
    const params = { extension, project_id: projectId };
    const options = {
      responseType: 'blob' as 'json',
      params,
    };
    return this.apiService.post<Nullable<Blob>>(`/projects/${projectId}/mix`, {}, options).pipe(
      map((blob) => {
        return new File([blob], `projectMix.${extension}`, { type: blob?.type });
      }),
      catchError((e) => {
        console.log(e);
        return of(null);
      }),
    );
  }

  private fetchProjectMix(projectId: string, extension = '.wav'): Observable<Nullable<File>> {
    return this.apiService
      .get<Nullable<Blob>>(`/projects/${projectId}/mix`, {
        params: { extension, project_id: projectId },
        responseType: 'blob',
      })
      .pipe(
        map((blob) => new File([blob], `projectMix.${extension}`, { type: blob?.type })),
        catchError((e) => {
          console.log(e);
          return of(null);
        }),
      );
  }
}
