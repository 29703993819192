import { createAction, props } from '@ngrx/store';
import { Track } from '../../interfaces/tracks/track';
import { FetchTracksPayload } from '../../interfaces/tracks/tracks-payload.models';

export const loadTracks = createAction(`[Tracks] Load Tracks`, props<{ params?: FetchTracksPayload }>());

export const loadTracksSuccess = createAction(`[Tracks] Load Tracks Success`, props<{ tracks: Track[] }>());

export const loadTracksFailure = createAction(`[Tracks] Load Tracks Failure`, props<{ error: any }>());

export const setPlayingTrack = createAction(
  `[Tracks] Set Playing Track`,
  props<{
    playingTrackIndex: number;
    startOffset?: number;
  }>(),
);

export const resetPlayingTrack = createAction(`[Tracks] Reset Playing Track`);

export const removeTrackForId = createAction(
  `[Tracks] Remove Playing Track`,
  props<{
    projectId: string;
    trackId: string;
  }>(),
);
