import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProjectsActions } from '.';
import { catchError, debounceTime, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import { ProjectsService } from '../../services/projects/projects.service';
import { Router } from '@angular/router';
import { CreateProjectPayload, UpdateProjectPayload } from '../../interfaces/projects/project-payload.models';
import { SnackbarService, SnackbarStatus } from '../../services/base/snackbar.service';
import { selectCurrentProjectId } from '@store/projects/projects.selectors';

@Injectable()
export class ProjectsEffects {
  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.loadProjects),
      debounceTime(100),
      switchMap(() => {
        return this.projectsService.fetchProjectsWithMixins().pipe(
          switchMap((projects) => [ProjectsActions.loadProjectsSuccess({ projects })]),
          catchError((error) => of(ProjectsActions.projectFailure({ error }))),
          takeUntil(this.actions$.pipe(ofType(ProjectsActions.loadProjects))),
        );
      }),
    ),
  );

  fetchProjectDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.fetchProjectDetails),
      debounceTime(100),
      switchMap(({ projectId }) => {
        return this.projectsService.fetchProjectWithDetails(projectId).pipe(
          switchMap((selectedProject) => [ProjectsActions.fetchProjectDetailsSuccess({ selectedProject })]),
          catchError((error) => of(ProjectsActions.projectFailure({ error }))),
        );
      }),
    );
  });

  deleteProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.deleteProject),
      debounceTime(200),
      switchMap(({ projectId }) => {
        return this.projectsService.deleteProject(projectId).pipe(
          switchMap(() => [ProjectsActions.loadProjects()]),
          tap(() => {
            this.snackbarService.open('Project has been successfully deleted', SnackbarStatus.SUCCESS, 4500);
          }),
          catchError((error) => of(ProjectsActions.projectFailure({ error }))),
        );
      }),
    );
  });

  createProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.createProject),
      debounceTime(100),
      switchMap((payload: CreateProjectPayload) => {
        return this.projectsService.createProject(payload).pipe(
          tap(({ projectId }) => {
            if (payload.shouldMix) {
              void this.router.navigate(['projects/details', projectId], { queryParams: { shouldMix: true } });
            } else {
              void this.router.navigate(['projects/list']);
            }
            this.snackbarService.open('Project has been successfully created', SnackbarStatus.SUCCESS, 4500);
          }),
          switchMap(() => {
            return [ProjectsActions.projectSuccess()];
          }),

          catchError((error) => of(ProjectsActions.projectFailure({ error }))),
        );
      }),
    );
  });

  updateProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.updateProject),
      debounceTime(100),
      switchMap((payload: UpdateProjectPayload) => {
        return this.projectsService.updateProject(payload).pipe(
          switchMap(() => {
            if (payload.shouldMix) {
              void this.router.navigate(['projects/details', payload.id], { queryParams: { shouldMix: true } });
            } else {
              void this.router.navigate(['projects/list']);
            }
            return [ProjectsActions.projectSuccess(), ProjectsActions.fetchProjectDetails({ projectId: payload.id })];
          }),
          tap(() => {
            this.snackbarService.open('Project has been successfully updated ', SnackbarStatus.SUCCESS, 4500);
          }),
          catchError((error) => of(ProjectsActions.projectFailure({ error }))),
        );
      }),
    );
  });

  downloadCurrentMixProject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectsActions.downloadCurrentMixProject),
        debounceTime(100),
        tap(({ file, name }) => {
          this.projectsService.downloadFile(file, name);
        }),
      ),
    { dispatch: false },
  );

  mixCurrentProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.mixCurrentProject),
      debounceTime(100),
      withLatestFrom(this.store.select(selectCurrentProjectId)),
      switchMap(([{ extension }, projectId]) => {
        return this.projectsService.createProjectMix(projectId, extension).pipe(
          switchMap((currentMix) => {
            return [ProjectsActions.projectMixedSuccess({ currentMix })];
          }),
          catchError((error) => of(ProjectsActions.projectMixFailure({ error }))),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly projectsService: ProjectsService,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService,
  ) {}
}
