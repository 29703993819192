import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';

const allowedUrlsParts: string[] = [];
const ignoredSegments = ['stripe'];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router, // private snackbar: SnackbarService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { token } = this.authService;
    if (token && !ignoredSegments.some((segment) => req.url.includes(segment))) {
      const enrichedRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return next.handle(enrichedRequest).pipe(this.handleError());
    }

    return next.handle(req);
  }

  private handleError(): OperatorFunction<HttpEvent<unknown>, HttpEvent<unknown>> {
    return catchError((err: HttpErrorResponse) => {
      if (allowedUrlsParts.some((urlPart) => err.url?.includes(urlPart))) {
        return throwError(() => err);
      }
      if (err.status === 403) {
        // this.snackbar.open('Error', SnackbarStatus.ERROR);
        void this.router.navigate(['landing/login']);
        this.authService.logout();
      }
      return throwError(() => err);
    });
  }
}
