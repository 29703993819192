import { Component } from '@angular/core';
import { MenuGroup } from './interfaces/menu-group';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { selectUser, selectUserHasPremium } from '@store/user/user.selectors';
import { User } from '../../interfaces/user/user';

const MENU_GROUPS: MenuGroup[] = [
  {
    groupTitle: 'MENU',
    children: [
      {
        title: 'Projects',
        redirect: '/projects',
        svgIcon: 'home',
      },
      // {
      //   title: 'Account',
      //   redirect: '/account',
      //   svgIcon: 'account',
      //   logInRequired: true,
      // },
      // {
      //   title: 'Settings',
      //   redirect: '/settings',
      //   svgIcon: 'settings',
      // },
      {
        title: 'Log out',
        redirect: '/projects/logout',
        svgIcon: 'logout',
        logInRequired: true,
      },
    ],
  },
];

@Component({
  selector: 'mxc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  user$: Observable<User> = this.store$.select(selectUser);
  menu$: Observable<MenuGroup[]> = this.user$.pipe(
    distinctUntilChanged(),
    map((user) => {
      if (user) {
        return MENU_GROUPS;
      }
      return MENU_GROUPS.map((group) => ({ ...group, children: group.children.filter((child) => !child.logInRequired) }));
    }),
  );
  hasPremium$ = this.store$.select(selectUserHasPremium);

  constructor(private readonly store$: Store) {}
}
