import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  private readonly iconNames: string[] = ['account', 'home', 'logout', 'options', 'play', 'save', 'settings'];

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
  ) {}

  init(): void {
    this.iconNames.forEach((name) => {
      const resourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`../../assets/icons/${name}.svg`);
      this.matIconRegistry.addSvgIcon(`mxc_${name}`, resourceUrl);
    });
  }
}
