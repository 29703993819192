import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TracksActions } from '.';
import { Track } from '../../interfaces/tracks/track';

export const tracksFeatureKey = `tracks`;

export interface State extends EntityState<Track> {
  loading?: boolean;
  playingTrackIndex: number;
  playingTrackStartOffset: number;
}

export const adapter: EntityAdapter<Track> = createEntityAdapter<Track>({
  selectId: (track) => track.id,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  playingTrackIndex: null,
  playingTrackStartOffset: 0,
});

export const reducer = createReducer(
  initialState,

  on(TracksActions.loadTracks, (state): State => ({ ...state, loading: true })),
  on(
    TracksActions.loadTracksSuccess,
    (state, { tracks }): State =>
      adapter.upsertMany(tracks, {
        ...state,
        loading: false,
      }),
  ),
  on(TracksActions.setPlayingTrack, (state, { playingTrackIndex, startOffset }) => ({
    ...state,
    playingTrackIndex,
    playingTrackStartOffset: startOffset || 0,
  })),
  on(TracksActions.resetPlayingTrack, (state) => ({ ...state, playingTrackIndex: null })),
);
