import { Component } from '@angular/core';
import { IconRegistryService } from './services/base/icon-registry.service';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet>`,
})
export class AppComponent {
  title = 'mix-console';

  constructor(private readonly iconRegistryService: IconRegistryService) {
    this.iconRegistryService.init();
  }
}
