import { fromUser } from './';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectUserState = createFeatureSelector<fromUser.State>(fromUser.userFeatureKey);

export const selectIsUserLoading = createSelector(selectUserState, (state) => state.isLoading);

export const selectUser = createSelector(selectUserState, (state) => state.user);

export const selectUserHasPremium = createSelector(selectUserState, (state) => Boolean(state.user?.premium_active || false));

export const selectIsAdmin = createSelector(selectUserState, (state) => state.user.roles?.includes('ADMIN'));

export const selectIsLoggedIn = createSelector(selectUserState, (state) => Boolean(state.user));

export const selectIsUserCreated = createSelector(selectUserState, (state) => Boolean(state.isUserCreated));

export const selectUserErrorCode = createSelector(selectUserState, (state) => state.errorCode);
