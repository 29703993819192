import { createAction, props } from '@ngrx/store';
import { Project } from '../../interfaces/projects/project.models';
import { CreateProjectPayload, UpdateProjectPayload } from '../../interfaces/projects/project-payload.models';
import { HttpErrorResponse } from '@angular/common/http';

export const loadProjects = createAction(`[Projects] Load Projects`);

export const projectSuccess = createAction(`[Projects] Project Success`);

export const projectFailure = createAction(`[Projects] Project Failure`, props<{ error: any }>());

export const loadProjectsSuccess = createAction(`[Projects] Load Projects Success`, props<{ projects: Project[] }>());

export const mixCurrentProject = createAction(`[Projects] Mix current project`, props<{ extension: string }>());

export const downloadCurrentMixProject = createAction(
  `[Projects] Download Current Mix Project`,
  props<{
    file: File;
    name?: string;
  }>(),
);

export const projectMixedSuccess = createAction(`[Projects] Project mixed success`, props<{ currentMix: File }>());

export const projectMixFailure = createAction(`[Projects] Project mix failure`, props<{ error: HttpErrorResponse }>());

export const createProject = createAction(`[Projects] Create Project`, props<CreateProjectPayload>());

export const updateProject = createAction(`[Projects] Update Project`, props<UpdateProjectPayload>());

export const fetchProjectDetails = createAction(`[Projects] Fetch Project Details`, props<{ projectId: string }>());

export const deleteProject = createAction(`[Projects] Delete Project`, props<{ projectId: string }>());

export const fetchProjectDetailsSuccess = createAction(
  `[Projects] Fetch Project Details Success`,
  props<{
    selectedProject: Project;
  }>(),
);
export const resetProjectDetails = createAction(`[Projects] Reset Project Details`);
