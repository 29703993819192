import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarStatusComponent } from '../../shared/components/snackbar-status/snackbar-status.component';

export enum SnackbarStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private matSnackBar: MatSnackBar) {}

  open(message: string, type: SnackbarStatus, duration = 3000): void {
    this.matSnackBar.openFromComponent(SnackbarStatusComponent, {
      data: { message, type },
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration,
    });
  }
}
