import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarStatus } from '../../../services/base/snackbar.service';

export interface SnackbarPayload {
  message: string;
  type: SnackbarStatus;
}

@Component({
  selector: 'app-snackbar-status',
  templateUrl: './snackbar-status.component.html',
  styleUrls: ['./snackbar-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarStatusComponent implements OnInit {
  currentClass!: string;
  message!: string;
  private readonly classByStatus = new Map<SnackbarStatus, string>([
    [SnackbarStatus.SUCCESS, 'success-container'],
    [SnackbarStatus.WARNING, 'warning-container'],
    [SnackbarStatus.ERROR, 'error-container'],
  ]);

  constructor(
    private snackBarRef: MatSnackBarRef<SnackbarStatusComponent>,
    @Inject(MAT_SNACK_BAR_DATA) private data: SnackbarPayload,
  ) {}

  ngOnInit(): void {
    this.currentClass = this.classByStatus.get(this.data.type) as string;
    this.message = this.data.message;
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
