import { Component } from '@angular/core';

@Component({
  selector: 'mxc-landing-core',
  template: `
    <div class="mxc-container">
      <div class="mxc-container landing__title">Mix your music using AI</div>
      <div class="mxc-container landing__content">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
})
export class LandingCoreComponent {}
