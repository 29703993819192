import { createFeatureSelector, createSelector } from '@ngrx/store';
import { fromTracks } from '.';

const {
  // select the array of ids
  selectIds,
  // select the dictionary of entities
  selectEntities,
  // select the array
  selectAll,
  // select the total count
  selectTotal,
} = fromTracks.adapter.getSelectors();

export const selectTracksState = createFeatureSelector<fromTracks.State>(fromTracks.tracksFeatureKey);

export const selectTracksLoading = createSelector(selectTracksState, (state) => state.loading);

export const selectTracks = createSelector(selectTracksState, selectAll);

export const selectPlayingTrackIndex = createSelector(selectTracksState, ({ playingTrackIndex }) => playingTrackIndex);

export const selectPlayingTrackStartOffset = createSelector(selectTracksState, ({ playingTrackStartOffset }) => playingTrackStartOffset);
