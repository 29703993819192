import { Component } from '@angular/core';

@Component({
  selector: 'mxc-core',
  template: `
    <div class="mxc-container">
      <div class="mxc-container__sidebar">
        <mxc-sidebar></mxc-sidebar>
      </div>
      <div class="mxc-container__content">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
})
export class CoreComponent {}
