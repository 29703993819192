import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Store, StoreModule } from '@ngrx/store';
import { reducers } from '@store/reducers';
import { fromProjects } from '@store/projects';
import { fromTracks } from '@store/tracks';
import { fromUser, UserActions } from '@store/user';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { ProjectsEffects } from '@store/projects/projects.effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SidebarModule } from './core/sidebar/sidebar.module';
import { TracksEffects } from '@store/tracks/tracks.effects';
import { UserEffects } from '@store/user/user.effects';
import { CoreComponent } from './core/core.component';
import { LandingCoreComponent } from './core/landing-core.component';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { UserService } from './services/user/user.service';
import { User } from './interfaces/user/user';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { Nullable } from './core/types/nullable.type';
import { AuthService } from './services/auth/auth.service';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';

function initializeAppFactory(userService: UserService, store$: Store, authService: AuthService): () => Observable<Nullable<User>> {
  return () => {
    const isTokenAvailable = Boolean(localStorage.getItem('token'));
    // if (!isTokenAvailable) {
    //   return of(null);
    // }
    authService.setupTokenIfAvailable();
    return userService.fetchUser().pipe(
      tap((user) => {
        store$.dispatch(UserActions.loadUserSuccess({ user }));
      }),
      catchError((error) => {
        store$.dispatch(UserActions.userActionFailure({}));
        return EMPTY;
      }),
    );
  };
}

@NgModule({
  declarations: [AppComponent, CoreComponent, LandingCoreComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    CommonModule,
    MatSnackBarModule,
    TranslateModule.forRoot(),
    StoreModule.forRoot(reducers),
    StoreModule.forFeature(fromProjects.projectsFeatureKey, fromProjects.reducer),
    StoreModule.forFeature(fromTracks.tracksFeatureKey, fromTracks.reducer),
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducer),
    EffectsModule.forRoot(),
    EffectsModule.forFeature([ProjectsEffects]),
    EffectsModule.forFeature([TracksEffects]),
    EffectsModule.forFeature([UserEffects]),
    SidebarModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [UserService, Store, AuthService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { ...new MatDialogConfig(), autoFocus: false },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
